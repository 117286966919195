import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/Button';

const Guide = () => {
  return (
    <Layout>
      <SEO title="Guide" />
      <h1>Help how do I butt?</h1>
      <p className="md:w-2/3">
        Relax, we got you. Here's what you need to know if you recently added
        ButtBot to your server:
      </p>
      <h2>Whitelisting Channels</h2>
      <p className="md:w-2/3">
        By default ButtBot will not do <em>anything</em> when it first arrives
        in your server. You need to give ButtBot permission to read a channel to
        get started. To do this, run the following command with the channel you
        want to whitelist:
      </p>
      <code className="md:w-2/3 mb-4">?butt whitelist #channel-name</code>
      <p className="md:w-2/3">
        Obviously, replace <strong>#channel-name</strong> with the channel you
        want to whitelist.
      </p>
      <h2>Giving other roles access to control ButtBot</h2>
      <p className="md:w-2/3">
        If you want other roles, like your moderators, to be able to control
        ButtBot you will need to give those roles access with the following
        command:
      </p>
      <code className="md:w-2/3 mb-4">?butt access @rolename</code>
      <h2>Adjusting Buttify Chance</h2>
      <p className="md:w-2/3 mb-4">
        If you want to adjust the chance the bot will choose a message, run the
        following command and input a float between 0 and 1. (For example, 0.5
        is 50% chance. 0.05 is 5% chance) <strong>Default value is 0.05</strong>
        .
      </p>
      <code className="md:w-2/3 mb-4">?butt setting chanceToButt 0.5</code>
      <h2>Adjust Butt Buffer</h2>
      <p className="md:w-2/3 mb-4">
        The Butt Buffer is how many messages after the bot buttifies a message
        before it will starting trying to choose a message again. This prevents
        the bot from spamming very active servers.{' '}
        <strong>Default value is 10.</strong>
      </p>
      <code className="md:w-2/3 mb-4">?butt setting buttBuffer 10</code>
      <h2>Disabling ButtAI (Voting)</h2>
      <p className="md:w-2/3 mb-4">
        ButtAI let's ButtBot learn what's funny and what isn't by letting users
        vote with reactions for each message. If you want to disable this, you
        can run the following command. By default, its enabled. (If you want to
        re-enable it, set it back to 1)
      </p>
      <code className="md:w-2/3 mb-4">?butt setting buttAI 0</code>
      <Button to="/" iconName="home">
        Home
      </Button>
    </Layout>
  );
};

export default Guide;
